import classnames from "classnames";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { getResourcesLink } from "~/constants/common";
import { trackEvent } from "~/utils/segment";

import { Button, ButtonLink } from "../Button";

interface Props {
  title?: string;
  description?: string;
  className?: string;
  resetFilters?: () => void;
}

const VoidPlaceholder: React.FC<Props> = ({
  title = "No usage yet",
  description = "Try filtering with a broader time frame, or check out our resources for getting students started with Pear Deck Tutor.",
  className,
  resetFilters
}: Props) => {
  const { selectedUmbrella } = useRequiredAuthContext();

  const handleReset = () => {
    if (resetFilters) {
      resetFilters();
      trackEvent("reset_all_filters", undefined);
    }
  };

  const trackResources = () => trackEvent("view_resources", undefined);

  return (
    <div
      className={classnames(
        className,
        "flex flex-col gap-[16px] items-center justify-center text-center h-full border-t border-cream-300"
      )}
    >
      <div className="subtitle1">{title}</div>
      <div className="paragraph max-w-[434px]">{description}</div>
      <Button
        variant="secondary"
        className="mt-[16px] mb-[-8px]"
        action={handleReset}
      >
        Reset all filters
      </Button>
      <ButtonLink
        href={getResourcesLink(selectedUmbrella.partnerType)}
        variant="text"
        target="_blank"
        rel="noopener noreferrer"
        action={trackResources}
      >
        Help your students get started
      </ButtonLink>
    </div>
  );
};

export default VoidPlaceholder;
